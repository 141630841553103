@import 'shared';

$default-border-radius: 5px !default;

// Round all corners by a specific amount, defaults to value of `$default-border-radius`.
//
// When two values are passed, the first is the horizontal radius
// and the second is the vertical radius.
//
// Note: webkit does not support shorthand syntax for several corners at once.
// So in the case where you pass several values, only the first will be passed to webkit.
//
// Examples:
//
//   .simple   { @include border-radius(4px, 4px); }
//   .compound { @include border-radius(2px 5px, 3px 6px); }
//   .crazy    { @include border-radius(1px 3px 5px 7px, 2px 4px 6px 8px);}
//
// Which generates:
//
//   .simple {
//     -webkit-border-radius: 4px 4px;
//     -moz-border-radius: 4px / 4px;
//     -khtml-border-radius: 4px / 4px;
//     border-radius: 4px / 4px;
//   }
//
//   .compound {
//     -webkit-border-radius: 2px 3px;
//     -moz-border-radius: 2px 5px / 3px 6px;
//     -khtml-border-radius: 2px 5px / 3px 6px;
//     border-radius: 2px 5px / 3px 6px;
//   }
//
//   .crazy {
//     -webkit-border-radius: 1px 2px;
//     -moz-border-radius: 1px 3px 5px 7px / 2px 4px 6px 8px;
//     -khtml-border-radius: 1px 3px 5px 7px / 2px 4px 6px 8px;
//     border-radius: 1px 3px 5px 7px / 2px 4px 6px 8px;
//   }

@mixin border-radius($radius: $default-border-radius, $vertical-radius: false) {
  @if $vertical-radius {
    // Webkit doesn't understand the official shorthand syntax for specifying
    // a vertical radius unless so in case there's several, we only take the first.
    @include experimental(border-radius, first-value-of($radius) first-value-of($vertical-radius), not -moz, -webkit, not -o, not -ms, not -khtml, not official);
    @include experimental('border-radius', $radius unquote('/') $vertical-radius, -moz, not -webkit, not -o, not -ms, -khtml, official);
  } @else {
    @include experimental(border-radius, $radius);
  }
}

// Round radius at position by amount.
//
// * legal values for `$vert`: `top`, `bottom`
// * legal values for `$horz`: `left`, `right`
@mixin border-corner-radius($vert, $horz, $radius: $default-border-radius) {
  // Support for mozilla's syntax for specifying a corner
  @include experimental(unquote('border-radius-#{$vert}#{$horz}'), $radius, -moz, not -webkit, not -o, not -ms, not -khtml, not official);
  @include experimental('border-#{$vert}-#{$horz}-radius', $radius, not -moz, -webkit, not -o, not -ms, -khtml, official);
}

// Round top-left corner only
@mixin border-top-left-radius($radius: $default-border-radius) {
  @include border-corner-radius(top, left, $radius);
}

// Round top-right corner only
@mixin border-top-right-radius($radius: $default-border-radius) {
  @include border-corner-radius(top, right, $radius);
}

// Round bottom-left corner only
@mixin border-bottom-left-radius($radius: $default-border-radius) {
  @include border-corner-radius(bottom, left, $radius);
}

// Round bottom-right corner only
@mixin border-bottom-right-radius($radius: $default-border-radius) {
  @include border-corner-radius(bottom, right, $radius);
}

// Round both top corners by amount
@mixin border-top-radius($radius: $default-border-radius) {
  @include border-top-left-radius($radius);
  @include border-top-right-radius($radius);
}

// Round both right corners by amount
@mixin border-right-radius($radius: $default-border-radius) {
  @include border-top-right-radius($radius);
  @include border-bottom-right-radius($radius);
}

// Round both bottom corners by amount
@mixin border-bottom-radius($radius: $default-border-radius) {
  @include border-bottom-left-radius($radius);
  @include border-bottom-right-radius($radius);
}

// Round both left corners by amount
@mixin border-left-radius($radius: $default-border-radius) {
  @include border-top-left-radius($radius);
  @include border-bottom-left-radius($radius);
}
