.loyalty__panel__offers__sku {
  @include breakpoint($medium-up) {
    padding: 0 5px;
  }
  border-bottom: 1px solid $color-gray;
  &__title {
    @include h8;
    margin: 40px 0 10px;
    font-size: 20px;
    line-height: 1;
    text-align: center;
    letter-spacing: normal;
  }
  &__loader {
    display: none;
    &__img {
      display: inline-block;
    }
    &__button {
      padding: 12px 20px;
      &.not_meet {
        pointer-events: none;
      }
      &.button--disabled {
        background-color: $color-light-gray;
      }
    }
  }
  &__list {
    &-container {
      .tabbed-rewards-block {
        &__content {
          &-item {
            height: 0;
            opacity: 0;
            overflow: hidden;
            transition: opacity 1s ease-in-out;
            width: 100%;
            &.current {
              height: auto;
              overflow: visible;
              opacity: 1;
            }
            .grid--mpp__items {
              .slick-dots {
                li {
                  margin: 0;
                  height: 17px;
                  width: 17px;
                  bottom: 26px;
                  button {
                    &:before {
                      font-size: 36px;
                    }
                  }
                }
              }
              .grid--mpp__item {
                .offer_not_meet {
                  &.disable_product {
                    &.button--disabled {
                      display: block;
                    }
                  }
                }
              }
            }
          }
          &-bottom {
            &-description {
              text-align: center;
              margin-top: 0;
            }
          }
        }
        &__tabs {
          @include breakpoint($medium-up) {
            padding: 0 80px;
          }
          padding: 0 13px;
          width: 100%;
        }
        &__tab {
          @include breakpoint($medium-up) {
            font-size: 12px;
          }
          padding: 10px 8px 16px;
          border-bottom: 1px solid $color-gray-light;
          color: $color-black;
          cursor: pointer;
          display: block;
          letter-spacing: 0;
          font-family: $body-font;
          font-size: 11px;
          float: $ldirection;
          line-height: 21px;
          text-decoration: none;
          text-transform: uppercase;
          width: 33.33%;
          margin-bottom: 30px;
          text-align: center;
          &.current {
            border-bottom: 1px solid $color-black;
            font-family: $body-font-bold;
          }
          &.rewards-eligible {
            color: $color-pink;
          }
        }
        .grid--mpp__carousel {
          animation-duration: 1.5s;
        }
        .product-brief-carousel {
          border-top: none;
        }
      }
      .quickshop {
        &__container {
          @include breakpoint($medium-up) {
            width: auto;
            margin-#{$ldirection}: 0;
          }
          .product {
            &-gallery {
              @include breakpoint($medium-up) {
                border-#{$rdirection}: none;
                padding-#{$rdirection}: 10px;
              }
              &__photo {
                &--large {
                  display: none;
                  &.active {
                    display: block;
                  }
                }
              }
              &__thumbs {
                display: none;
              }
            }
            &__divider {
              display: none;
            }
            &-replenishment {
              &-select {
                display: none;
              }
            }
            &__actions {
              @include swap_direction(padding, 0);
              .product {
                &__button {
                  background: $color-black;
                  margin-top: 20px;
                }
              }
            }
            &__details {
              @include breakpoint($medium-up) {
                @include swap_direction(padding, 40px 20px 20px 5px);
              }
              .product {
                &__header {
                  display: block;
                  border: none;
                }
                &__title {
                  @include breakpoint($medium-up) {
                    display: inline-block;
                  }
                  font-size: 10px;
                }
                &__rating {
                  display: none;
                }
                &__actions {
                  text-align: $ldirection;
                  .product {
                    &__full-details-link-wrapper,
                    &__favorite {
                      display: none;
                    }
                  }
                }
              }
              .mobile-price,
              .rating_fav {
                display: none;
              }
              .quickshop-container__offer__needed__pts {
                float: $rdirection;
                font-family: $body-font-bold;
              }
            }
            &__sku {
              &-categories {
                &-nav {
                  display: none;
                  &-bottom {
                    border: 1px solid $color-border-lighter-gray;
                  }
                }
              }
              &-select {
                &-label {
                  display: none;
                }
              }
            }
            &__promo {
              display: none;
            }
            &_quickshop {
              &__header {
                font-size: 14px;
              }
            }
          }
          &__offer {
            &-needed {
              &-pts {
                float: $rdirection;
                font-family: $body-font-bold;
              }
            }
          }
        }
        &-wrapper {
          &.active {
            &--1 {
              &:before {
                #{$ldirection}: 50%;
                @include breakpoint($medium-up) {
                  #{$ldirection}: 7%;
                }
              }
            }
            &--2 {
              &:before {
                #{$ldirection}: 50%;
                @include breakpoint($medium-up) {
                  #{$ldirection}: 41%;
                }
              }
            }
            &--3 {
              &:before {
                #{$ldirection}: 50%;
                @include breakpoint($medium-up) {
                  #{$ldirection}: 75.66%;
                }
              }
            }
          }
        }
        .wrap {
          @include swap_direction(padding, 0);
        }
      }
    }
  }
  &__subtitle {
    @include breakpoint($medium-up) {
      font-size: 15px;
    }
    @include h7;
    font-size: 14px;
    line-height: 1.25;
    text-align: center;
    padding-bottom: 25px;
  }
  &__select {
    @include h7;
    text-align: center;
    line-height: 1.25;
  }
  &__benefits {
    text-align: center;
    margin-top: 5px;
    &__link {
      text-decoration: underline;
      font-size: 18px;
      text-transform: capitalize;
    }
  }
  &__product {
    padding: 1.3em;
    margin: 1.3em;
    text-align: center;
    &__image {
      &-container {
        @include breakpoint($medium-up) {
          height: 250px;
        }
        &.rewards-not-eligible {
          opacity: 0.2;
        }
      }
    }
    &__header {
      @include h8;
      font-size: 16px;
      line-height: 1;
      margin-bottom: 10px;
      letter-spacing: normal;
      min-height: 50px;
    }
    &__sub-header {
      font-size: 16px;
      margin-bottom: 10px;
      line-height: 1.15;
      font-family: $body-font;
      min-height: 36px;
    }
    &__offer-code {
      font-family: $body-font-bold;
      padding: 10px 0;
      text-transform: uppercase;
    }
  }
  &__shade-container {
    @include clearfix;
    margin-top: 4px;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    letter-spacing: 0.2em;
  }
  &__shade {
    @include border-radius(18px / 2);
    display: inline-block;
    height: 18px;
    width: 18px;
    margin-#{$rdirection}: 4px;
  }
  &__shadename {
    vertical-align: top;
  }
  .carousel-controls {
    position: relative;
  }
  .slick-next,
  .slick-prev {
    @include breakpoint($medium-up) {
      position: absolute;
      height: auto;
    }
    top: 200px;
    height: 0;
    &:before {
      @include breakpoint($medium-up) {
        border-radius: 31px;
        width: 28px;
        height: 28px;
        font-size: 20px;
      }
      border-radius: 20px;
      padding-top: 7px;
      font-size: 23px;
      background: $color-white;
      position: relative;
      width: 40px;
      height: 40px;
    }
  }
  .slick-prev {
    @include breakpoint($medium-up) {
      #{$ldirection}: -12px;
      margin-#{$ldirection}: 0;
    }
    margin-#{$ldirection}: 9px;
    &:before {
      @include breakpoint($medium-up) {
        @include swap_direction(padding, 4px 5px 0 0);
      }
      @include swap_direction(padding, 7px 4px 0 0);
    }
  }
  .slick-next {
    @include breakpoint($medium-up) {
      margin-#{$rdirection}: 0;
      #{$rdirection}: -14px;
    }
    margin-#{$rdirection}: 3px;
    #{$rdirection}: 0;
    &:before {
      #{$rdirection}: 0;
      @include breakpoint($medium-up) {
        @include swap_direction(padding, 4px 0 0 5px);
        #{$rdirection}: auto;
      }
    }
  }
  &__more {
    &__link {
      color: $color-black;
    }
  }
}

.loyalty__offer__terms {
  padding: 20px 0;
  font-size: 13px;
  &__link {
    margin-top: 15px;
    .terms-link {
      text-decoration: underline;
    }
  }
}
