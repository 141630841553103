.site-header {
  .site-utils__item {
    .cart_overlay {
      .cart-product-preorder {
        float: left;
        font-size: 12px;
      }
    }
    .site-utils__navigation {
      &--loyalty {
        @include breakpoint($medium-up) {
          padding: 3px 16px 0;
          letter-spacing: 0;
        }
        @include breakpoint($medium-landscape-only) {
          padding-#{$rdirection}: 0;
        }
        &-header {
          @include breakpoint($medium-up) {
            vertical-align: top;
          }
        }
      }
    }
  }
}

.mpp,
.spp,
.spp-mobile-page {
  .product__flag {
    display: inline-block;
  }
}

.mpp {
  @include breakpoint($medium-down) {
    .product__flag_preorder {
      margin-bottom: 5px;
    }
  }
}

.spp {
  .product {
    .product__title {
      .product__flag {
        display: inline-block;
      }
    }
  }
}

.site-header {
  &__addtobag {
    .product__inventory-status {
      .preorder-noshop {
        display: none !important;
      }
    }
  }
}

.pre_order_temp_out_of_stock {
  .temp_out_of_stock_msg {
    display: none;
  }
}

.peace_love_beach {
  .quickshop-wrapper {
    .button {
      float: none;
    }
  }
}

.product {
  &__inventory-status {
    .preorder-noshop {
      font-size: 11px;
      text-align: left;
      .spp-mobile-page & {
        text-align: center;
      }
      @include breakpoint($medium-up) {
        width: 170px;
        float: right;
        font-size: 12px;
        text-align: right;
      }
    }
  }
  &__notify-me {
    @include breakpoint($medium-down) {
      .button {
        width: 100%;
        margin-top: 10px;
      }
    }
  }
}

.confirmation-panel {
  &__content {
    .pre-order-message-display {
      font-size: 14px;
    }
  }
}

#results-container {
  .product__flag {
    display: inline-block;
  }
}

.favorites-page,
.past-purchases {
  &__content {
    .pre_order_message_detail {
      font-size: 12px;
    }
  }
}

.spp-mobile-page {
  .shaded_details {
    .product__inventory-status {
      .preorder-noshop {
        text-align: left;
      }
    }
  }
}

.lp_survey_view_container {
  .lp_pages_area {
    .lp_radiobutton_wrapper {
      input[type='radio'] {
        display: none;
      }
    }
  }
}

.device-mobile {
  .site-footer-contact #artist_chat_link {
    .icon-livechat:before {
      left: 15px;
    }
  }
  .site-utils__nav .menu__link--lvl-1 {
    #artist_menu_chat a.menu__link--lvl-1 {
      padding-left: 0;
    }
  }
  .in_store_guide {
    #store_guide_chat h4:before {
      top: -36px;
    }
  }
  .menu__image {
    &--lvl-1 {
      width: 20px;
      height: 20px;
      margin-#{$ldirection}: 10px;
      vertical-align: middle;
    }
  }
  .menu__link {
    &--text {
      text-decoration: none;
      color: $color-black;
    }
  }
  .customer-service-section {
    div#artist_chat {
      display: block;
    }
    a.customer-service-landing-block__link {
      display: inline-block;
      position: initial;
      margin-top: 15px;
      bottom: 24px;
      letter-spacing: 1px;
    }
    #landing-chat {
      border-top: none;
    }
    .customer-service-landing-block {
      &:nth-child(2),
      &:nth-child(4) {
        border-top: 1px solid $color-gray-alt;
      }
      &:nth-child(2) {
        .customer-service-landing-block {
          &__header {
            background: none;
          }
        }
        div {
          display: block;
        }
      }
    }
  }
  .site-footer-contact {
    &__menu {
      display: grid;
    }
    &__item {
      &--chat {
        order: 1;
      }
      &--text {
        order: 2;
        .site-footer-contact {
          &__link {
            text-align: $ldirection;
          }
        }
      }
      &--email {
        order: 3;
      }
      &--phone {
        order: 4;
      }
      &--in_store {
        order: 5;
      }
      &--text,
      &__link {
        float: $ldirection;
      }
    }
    &__image {
      @include swap_direction(margin, 0 6px 0 12px);
      width: 25px;
      height: 25px;
    }
  }
  .customer-service-landing-block {
    &:nth-child(2) {
      .text-now {
        display: block;
        a {
          padding-bottom: 0;
          bottom: 25px;
        }
      }
    }
  }
}

.how-to-use__content {
  .disclaimer {
    display: block;
  }
}

.customer-service-landing-block {
  &__image {
    width: 50px;
    height: 42px;
  }
}

@include breakpoint($medium-up) {
  .customer-service-header {
    margin-bottom: 25px;
  }
  .customer-service-landing-block {
    &__text-artist {
      padding-bottom: 0.5em;
      font-family: $brandon-text-bold;
      text-decoration: none;
      text-transform: uppercase;
      position: absolute;
      #{$ldirection}: 0;
      display: block;
      width: 100%;
      text-align: center;
      color: $color-pink;
    }
  }
  .customer-service-landing-block {
    &:first-child {
      border-#{$rdirection}: 1px solid $color-gray-alt;
    }
  }
  .customer-service-landing-block {
    &:nth-child(4) {
      background: none;
      border-top: 1px solid $color-gray-alt;
    }
  }
  .site-footer-contact {
    padding: 10px 0;
    &__menu {
      display: flex;
    }
    &__item {
      width: 25%;
      &--email,
      &--phone,
      &--text,
      &--chat {
        padding: 15px 0;
        letter-spacing: 2px;
      }
      &--chat {
        order: 1;
        width: 28%;
      }
      &--text {
        order: 2;
        position: relative;
        #{$rdirection}: 10px;
        .site-footer-contact {
          &__link {
            @include swap_direction(margin, 10px 0 0 60px);
          }
        }
      }
      &--email {
        order: 3;
        position: relative;
        #{$ldirection}: 40px;
        width: 22%;
      }
      &--phone {
        order: 4;
        position: relative;
        #{$ldirection}: 15px;
      }
    }
    &__link {
      margin: 0;
    }
    &__image {
      width: 50px;
      float: $ldirection;
    }
  }
}
// Sticky Header
body.js-sticky-header {
  .site-utils--sticky .site-utils__menu--v2 {
    display: inline;
  }
}

.esearch_results--pc {
  &.wrap {
    @include breakpoint($medium-up) {
      max-width: 1444px;
      .product-grid__content {
        max-width: inherit;
        .pr-category-snippet__total {
          display: block;
        }
      }
    }
  }
  &.esearch-results__section {
    @include breakpoint($medium-up) {
      max-width: 1024px;
    }
  }
}

.search-results__header--desktop {
  @include breakpoint($medium-up) {
    .search-form__results-count {
      line-height: 2.4;
      #{$rdirection}: 180px;
    }
    input[type='submit'].search-form__submit {
      margin: 0;
    }
  }
}

.section-esearch {
  .product-grid__content {
    .p-w-r {
      .pr-snippet {
        .pr-category-snippet__total {
          display: table-cell;
        }
      }
    }
  }
}

.sign-in-page,
.checkout__return-user {
  .new-account__legalupdate {
    font-size: 12px;
    padding-bottom: 10px;
  }
}
.checkout__content,
.sign-in-page {
  .social-login__contents {
    display: flex;
    flex-flow: column;
    .social-login__email-opt-in {
      order: 2;
    }
    .social-login__terms {
      order: 1;
    }
    .fb-login-button {
      order: 3;
    }
  }
}
.site-footer-email-signup__terms-conditions {
  span:first-of-type {
    display: block;
    padding-bottom: 6px;
  }
  a {
    display: inline;
    color: $color-white;
  }
}

.site-email-sms-signup {
  padding-top: 25px;
  @include breakpoint($medium-up) {
    max-width: 880px;
    background: url('/media/images/account/email-sms-signup-envelope-bg.gif') no-repeat center -10px;
    padding: 25px 10px 15px;
    margin-bottom: 100px;
    border: 10px solid $color-grey-border-light;
  }
  .site-email-sms-signup__header {
    margin-bottom: 0;
    .at-h2 {
      height: auto;
    }
  }
  .site-email-sms-signup__form {
    .site-email-sms-signup {
      &__field {
        margin: 7px 10px;
        vertical-align: top;
        &[name='SMSPROMO_MOBILE_NUMBER'] {
          display: none;
        }
      }
      &__checkbox {
        &[name='SMSPROMO_SMS_PROMOTIONS'] {
          display: none;
        }
      }
    }
    .site-email-sms-signup__birthday {
      fieldset {
        margin-top: 5px;
      }
    }
    .site-email-sms-signup__agree--terms {
      &:not(.email-promo) {
        display: none;
      }
      font-size: 13px;
    }
  }
}
.marketing__enrollment {
  .marketing__enrollment__accept__terms {
    .label::before {
      display: none;
    }
  }
}
.welcome15-overlay {
  .welcome-overlay__checkbox {
    .label__content {
      width: 93%;
    }
  }
}
.marketing__enrollment__accept__terms {
  .label-content {
    width: 92%;
    a {
      color: $color-black;
    }
  }
}
.site-email-signup__form {
  .sign-in-component__legal_text_updates {
    a {
      color: $color-black;
    }
  }
}
.social-login.gnav {
  padding: 10px 0 0;
  .social-login__container {
    display: flex;
    flex-direction: column;
    .fb-overlay-container {
      order: 3;
      margin-top: 15px;
    }
    .social-login {
      &__email-container {
        order: 1;
      }
      &__terms {
        order: 2;
      }
      &__divider {
        margin-bottom: 0;
      }
      &__legal-updates {
        text-align: #{$ldirection};
      }
      &__email-opt-in {
        float: #{$ldirection};
      }
    }
  }
}
.site-utils__dropdown {
  .sign-in {
    .registration__container {
      .registration {
        &__terms,
        &__email-list {
          display: none;
        }
        &__legal-update-text {
          text-align: #{$ldirection};
          padding-bottom: 15px;
        }
      }
    }
  }
}
.product-full__ctas {
  .product-full__ctas-non-shaded-wrapper {
    .product-full__inv-status {
      min-height: 41px;
      @include breakpoint($medium-up) {
        width: 100%;
      }
    }
  }
}
.sticky-add-to-bag__ctas-wrapper {
  .sticky-add-to-bag__status {
    .product__inventory-status {
      display: inline;
      @include breakpoint($portrait-up) {
        display: flex;
      }
    }
  }
}

.payment-form,
.payment-address {
  &__choose-address {
    a {
      @include breakpoint($landscape-up) {
        width: 92% !important;
      }
    }
    select {
      padding-inline-start: 10px;
      padding-inline-end: 22px;
      width: 100%;
    }
  }
}
