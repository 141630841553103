// WP Overlay
.wp-overlay-container__panel {
  border: none;
  padding: 1em;
  margin: 0px;
  height: 330px;
  width: 555px;
}

// WP Overlay Content
.wp-overlay-container__wrapper {
  height: auto;
  overflow: hidden;
  padding-top: 20px;
  width: 100%;
  @include breakpoint($medium-up) {
    height: 260px;
  }
  .wp-overlay-container {
    height: 100%;
    overflow: auto;
    &__sidebar {
      width: 25%;
      float: left;
      .wp-sidebar-image {
        background-image: url(/media/promotions/wp/gwp_popup.jpeg);
        background-size: 100% auto;
        height: 330px;
        left: 0;
        position: absolute;
        top: 0;
        width: 132px;
      }
    }
    .wp-overlay-content {
      width: auto;
      float: left;
      margin-left: 10px;
      @include breakpoint($medium-up) {
        margin-left: 137px;
      }
      span {
        color: #040a2b;
      }
      a {
        text-decoration: underline;
      }
    }
    p {
      line-height: 1.43em;
      color: #999999;
    }
    .remove-buttons {
      a {
        font-size: 0.9em;
      }
      p {
        margin-bottom: 1em;
      }
    }
  }
}

// GWP Samples page
.gwp-sample-page {
  max-width: 1066px;
  margin-bottom: 50px;
  .product {
    padding: 0 37.5px 90px;
    width: 33.3%;
  }
  input[type='checkbox'],
  input[type='radio'] {
    left: 15px;
    position: absolute;
  }
  .samples-panel__title {
    text-align: center;
  }
  .samples-page .samples-panel__title {
    font-size: 43px;
    letter-spacing: -0.02em;
  }
  .skus {
    bottom: auto;
    margin-top: -20px;
  }
  label.gwp-radio-label,
  .gwp_messages,
  .return-link a,
  .remaining p {
    font-size: 12px;
    letter-spacing: 0.15em;
    text-align: center;
    text-transform: uppercase;
    font-family: 'AkzidenzGrotesk';
  }
  .checked label.gwp-radio-label {
    color: #040a2b;
  }
  label.gwp-radio-label {
    color: #a4a4ab;
  }
  .return-link a {
    font-weight: bold;
  }
  .remaining {
    text-align: right;
    width: 74%;
  }
}

div.gwp_messages {
  background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
  border: medium none;
  color: #9eafff;
  margin: 0;
  padding: 0;
  padding: 10px 0 10px 0;
}

div.gwp_messages a {
  color: #9eafff;
  text-decoration: underline;
}

.gwp-sample-page {
  .skus {
    bottom: auto;
    margin-top: -20px;
  }
  label.gwp-radio-label,
  .gwp_messages,
  .return-link a,
  .remaining p {
    font-size: 12px;
    letter-spacing: 0.15em;
    text-align: center;
    text-transform: uppercase;
    font-family: 'AkzidenzGrotesk';
  }
  .checked label.gwp-radio-label {
    color: #040a2b;
  }
  label.gwp-radio-label {
    color: #a4a4ab;
  }
  .return-link a {
    font-weight: bold;
  }
  .remaining {
    text-align: right;
    width: 74%;
  }
  .samples-top {
    padding-bottom: 0px;
  }
  .learn-link {
    display: block;
    line-height: 45px;
    letter-spacing: 1.5px;
    font-size: 12px;
    font-weight: bold;
    a {
      text-decoration: underline;
    }
  }
  .gwp-label-wrap {
    margin-bottom: 10px;
  }
  .gwp-footer-top {
    margin-bottom: 20px;
    text-align: left;
  }
  .return-link {
    line-height: 22px;
  }
  .product-list {
    padding-top: 30px;
  }
  .remaining p {
    margin-bottom: 13px;
  }
  .samples-panel__title {
    padding-right: 0;
    padding-top: 0px;
    margin-bottom: 0px;
  }
  .samples-panel header {
    margin-bottom: 13px;
  }
  .gwp-footer-bottom {
    margin-bottom: 20px;
    text-align: left;
  }
  .gwp-label-wrap:nth-last-child(1) {
    margin-bottom: 0;
  }
  /* Checkbox Fixes */
  .gwp-label-wrap input[type='radio'],
  .gwp-label-wrap input[type='checkbox'] {
  }
  .gwp-label-wrap input[type='radio'] ~ label,
  .gwp-label-wrap input[type='checkbox'] ~ label {
    padding-top: 2px;
  }
  .gwp-label-wrap input[type='radio']:checked ~ label,
  .gwp-label-wrap input[type='checkbox']:checked ~ label {
    color: #040a2b;
  }
  .gwp-label-wrap input[type='radio'] ~ label:before,
  .gwp-label-wrap input[type='radio'] ~ label:after,
  .gwp-label-wrap input[type='checkbox'] ~ label:before,
  .gwp-label-wrap input[type='checkbox'] ~ label:after {
    border-radius: 0px;
    box-shadow: none;
    border: 1px solid #cccccc;
  }
  .gwp-label-wrap input[type='radio'] ~ label:after,
  .gwp-label-wrap input[type='checkbox'] ~ label:after {
    background: url('/sites/esteelauder/themes/estee_base/img/sprites/checked.png');
  }
  .product {
    height: 100% !important;
  }
  .samples-panel .add-button {
    display: block;
  }
}

#wp-submit-btn {
  width: 130px;
  height: 33px;
}

#wp-submit-btn:hover {
  background-image: url('/media/promotions/gwp/summer_2014/GWP_may14_choice_addtobag.jpg');
}

.samples-page .product .sample-select-button {
  &.selected {
    background-color: $color-pink;
  }
}
