$mobile-landscape: 0px (max-height 480px) (orientation landscape) !default;

@keyframes video-loading-circle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.video-play-trigger {
  transition: 0.2s transform ease-in;
  color: $color-white;
  @include icon('play');
  &:hover {
    transform: translate(-50%, -50%) scale(1.1, 1.1);
  }
}

.videojs-video { // i..e video element
  position: relative;
  background: $color-black;
  // 16:9 ratio youtube
  padding-top: 56.25%;
  // this is largely here to match designs.
  // youtube videos are 16-9 so like, this won't play full height

  &__player {
    // 16:9 ratio youtube
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index: 0;
  }

  &__play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;
    z-index: 100;
    &:hover {
      cursor: pointer;
    }
    @include breakpoint($medium-up) {
      font-size: 70px;
    }
  }

  &__landing {
    overflow: hidden;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    z-index: 1;

    &__inner {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
    }
    &__image img {
      position: absolute;
      max-width: none;
      max-height: 100%;
      left: 50%;
      height: 100%;
      width: auto;
      transform: translateX(-50%);
      [class*='player-aspect-ratio-'] & {
        object-fit: cover;
      }
    }
  }
  &.videojs-video--loading &__landing {
    .video-play-trigger {
      &:after {
        position: absolute;
        top: -5px;
        left: -5px;
        content: " ";
        display: block;
        margin: 0px;
        width: calc(100% + 10px);
        height: calc(100% + 10px);
        border-radius: 50%;
        border: 11px solid transparent;
        border-color: $color-pink transparent transparent transparent;
        animation: video-loading-circle 1.5s linear infinite;
      }
    }
  }

  .video-js {
    @include breakpoint($medium-up) {
      min-height: auto;
    }
    @include breakpoint($mobile-landscape) {
      max-width: 100vw;
      height: calc(100vh - 20px);
      padding-top: 0px;
    }
    .vjs-control-bar {
      background-color: transparent;
    }
    // we use our own play button
    .vjs-big-play-button {
      display: none;
    }
    &.vjs-has-started .vjs-big-play-button {
      display: none;
    }
    .vjs-marker {
      &:before {
        font-family: VideoJS;
        font-weight: normal;
        font-style: normal; 
        color: $color-white;
        content: "\f111";
        font-size: .9em;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.video-detail-cuepoint-target {
  display: block;
  @include breakpoint($medium-up) {
    display: none;
  }
}

// relative for video card positioning
.video-detail__video-wrapper {
  position: relative;
}

.video-product-cuepoint {
  height: 0;
  opacity: 0;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  transition: height 0ms 500ms, top 500ms 0ms, opacity 500ms 0ms;
  top: -100px;
  position: relative;
  z-index: 2;

  @include breakpoint($medium-up) {
    transition: opacity 500ms 0ms;
    top: auto;
    bottom: 30px;
    left: 200px; // just a default.
    position: absolute;
    margin-left: 0;
    margin-right: 0;
  }

  .video-cuepoint-mobile & {
    top: auto !important;
    left: auto !important;
    position: absolute !important;
    transform: none !important;
  }
  &.reveal {
    transition: height 0ms 0ms, top 500ms 0ms, opacity 500ms 0ms;
    top: 0px;
    height: max-content;
    opacity: 1;
    @include breakpoint($medium-up) {
      transition: opacity 500ms 0ms;
      top: auto;
      height: auto;
    }
  }

  &.mantle-placement--active {
    @include breakpoint($medium-up) {
      top: auto;
      bottom: auto;
      left: auto;
    }
  }

  &:not(.mantle-placement--active) {
    @include breakpoint($medium-up) {
      transform: translate(-50%, -0%);
    }
  }

  &.onload-marker {
  }

  &--has-explicit-position,
  &--has-explicit-position.onload-marker {
    left: auto;
    @include breakpoint($medium-up) {
      transform: none;
    }
  }
}
