.nude_finish {
  .product_collection_hero {
    &__content-item {
      &:nth-child(1) .product-collection-product__product-name:after {
        content: url(/media/export/cms/evergreen/corrector-concealer/corrector-concealer_shipping-seal.jpg);
        position: absolute;
        top: 0;
        right: 20px;
      }
      &:nth-child(3) {
        .product-collection-product__product-name:after {
          content: url(/media/export/cms/evergreen/corrector-concealer/corrector-concealer_shipping-seal.jpg);
          position: absolute;
          top: 0;
          right: 30px;
        }
      }
    }
  }
}
