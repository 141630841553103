.adpl {
  input,
  select,
  .selectBox {
    &[data-error] {
      border-color: red;
    }
  }
  select {
    &[data-error] + a {
      border-color: red;
    }
  }
  input {
    &[data-error] + label {
      border-color: red;
    }
  }
}
