// Checkout Viewcart overrides
.checkout.viewcart {
  .apple-pay-button-container {
    border: 0;
    height: 36px;
    .apple-pay-button {
      -apple-pay-button-type: check-out;
      border-radius: 0px;
      width: 100%;
    }
  }
  #sticky-checkout-button-overlay {
    .apple-pay-button-container {
      border: 0;
      height: 43px;
    }
  }
}

body {
  &.viewcart {
    overflow-x: visible !important;
  }
}
