.checkout-confirmation-page {
  .checkout__content {
    padding-right: 0;
    @include breakpoint($medium-up) {
      width: 100%; //59%;
      margin-right: 3%;
    }
  }
  .checkout__sidebar {
    @include hidden;
  }
  .confirmation-panel__content,
  .pre-order-message-display {
    margin: 0;
  }
  .registration-panel {
    width: 100%;
  }
  .guarantee-panel {
    @include breakpoint($medium-up) {
      padding-#{$ldirection}: 0;
    }
    &__loyalty {
      @include breakpoint($medium-up) {
        padding-#{$ldirection}: 20px;
      }
    }
  }
}

.opc__registration {
  &.index {
    padding: 20px;
    .checkout {
      &__panel {
        &.collapsed,
        &.finished,
        &.display {
          display: none;
        }
      }
    }
    .show-password {
      margin-bottom: 10px;
    }
  }
}
