.newsletter-info,
.email-and-sms-promotions {
  &__fieldset {
    .newsletter-info__item {
      span.radio {
        display: none;
      }
    }
  }
  .pc_email_promo_container {
    .email_promotions_sub_section {
      span.radio {
        display: none;
      }
    }
  }
}

.cart-item {
  &__price {
    span.cart_percent--discounted {
      color: $color-pink;
    }
    del {
      color: $color-black;
      text-decoration: line-through;
    }
  }
}

.customer-service-section {
  div#artist_chat {
    display: block;
    a {
      top: 0px;
    }
  }
  div#order_chat {
    display: block;
    a {
      top: 0px;
    }
  }
}

#terms_chat a.customer-service-quick-info__link {
  margin-left: 60px;
  padding-top: 10px;
  cursor: pointer;
  span.icon {
    top: 1px;
  }
}

.in_store_guide #store_guide_chat p {
  letter-spacing: 1px;
}

#live_chat,
#photo_chat,
#live_order_chat {
  position: absolute;
  bottom: 0;
  #{$ldirection}: 0;
  #{$rdirection}: 0;
  a {
    letter-spacing: 1px;
  }
}
.section-live-chat-landing {
  #live_chat,
  #photo_chat,
  #live_order_chat {
    position: static;
    a {
      position: static;
      color: $color-core-black;
      font-size: 15px;
      padding-top: 10px;
      display: block;
      text-decoration: underline;
      letter-spacing: 0;
    }
  }
  .reach_out {
    .button--inverted {
      color: $color-core-black;
      font-size: 15px;
      text-decoration: underline;
      background: transparent;
      letter-spacing: 0;
      font-family: $bbtext-regular;
    }
  }
}

#footer_artist_chat {
  a.site-footer-contact__link {
    cursor: pointer;
  }
}

#footer_sticky_chat {
  min-height: 65px;
}

.device-mobile {
  #footer_sticky_chat {
    min-height: 63px;
  }
  #gnav_chat a {
    padding-left: 0px;
    letter-spacing: 1px;
  }
  #home_brushup_chat {
    width: 50%;
    height: 100%;
    div,
    a {
      width: 100%;
      height: 100%;
    }
  }
}

.touch .device-mobile {
  #lpChat .lp_lpview_content select {
    -moz-appearance: menulist;
    -webkit-appearance: menulist;
    appearance: menulist;
  }
}

.hide-checkout-buttons {
  display: none;
}

// CX-5843 - Year has to be removed from several pages
.popup-offer {
  &__sign-up-form {
    .selectbox {
      display: inline;
      float: none;
      &.select-year {
        display: none;
      }
    }
  }
}

.sign-in {
  &-component__birthday-program {
    .day-of-birth__collection-field {
      a {
        &.select-year {
          display: none !important;
        }
      }
    }
  }
}

.site-utils__item--email {
  .site-email-signup__form {
    .sign-in-component__birthday-program {
      .day-of-birth__collection-field {
        a {
          &.select-day {
            margin-#{$rdirection}: 0 !important;
          }
        }
      }
    }
  }
}

.sign-birthday-info {
  .optional-info__birthday-day {
    margin-right: 35%;
  }
}

.optional-info {
  .birthday {
    .optional-info__item {
      @include swap_direction(padding, 10px 0 5px 10px);
      width: 32%;
      margin-#{$rdirection}: 10px;
    }
  }
}

.registration-pro-page {
  .optional-info {
    &__birthday {
      &-select {
        @include breakpoint($medium-up) {
          width: 13%;
          min-width: 13%;
        }
      }
    }
  }
}

.site-email-sms-signup {
  @include breakpoint($medium-down) {
    background-size: 100% 85%;
  }
  &__birthday {
    text-align: left;
  }
  &__agree {
    p.site-email-sms-signup__agree--terms {
      line-height: 1.45em;
    }
  }
  &__header {
    @include breakpoint($small-down) {
      @include swap_direction(padding, 40px 15px 40px);
    }
  }
}

.address-form {
  .form-item,
  &__item {
    .selectBox-disabled {
      background-color: $color-gray-lighter;
      &:hover {
        color: $color-black;
      }
      .selectBox-arrow {
        background-color: $color-gray-lighter;
      }
    }
    select {
      &.selectbox:disabled {
        background-color: $color-gray-lighter;
      }
    }
  }
}

.wrap.site-footer__wrap {
  .sticky-back-to-top-v2 {
    &.sticky-back-to-top-new {
      bottom: 120px;
      @include breakpoint($medium-up) {
        bottom: 50px;
      }
    }
  }
  .sticky-footer-chat {
    &.sticky-footer-new-chat {
      a {
        bottom: 198px;
        width: 70px;
        #{$rdirection}: 7px;
        @include breakpoint($medium-up) {
          bottom: 142px;
          width: 68px;
          #{$rdirection}: 40px;
        }
      }
    }
  }
}
.section-store-locator {
  .homepage_evergreen_services {
    padding: 0 60px;
    &_body {
      @include breakpoint($medium-up) {
        display: flex;
        padding-bottom: 30px;
      }
      .homepage_evergreen_services_image,
      .hed,
      .dek,
      .link {
        padding: 0 14px;
      }
      a {
        padding: 0 1%;
        text-decoration: none;
        @include breakpoint($medium-up) {
          width: 25%;
        }
      }
      .dek {
        font-family: $body-font;
        font-size: 16px;
        letter-spacing: 0;
        color: $color-black;
        margin: 0.75rem auto 0;
      }
      .link {
        font-family: $body-font;
        font-size: 12px;
        letter-spacing: 1px;
        text-decoration: underline;
        border: none;
        margin-top: 1rem;
      }
      .hed {
        font-family: $body-font-bold;
        font-size: 16px;
        letter-spacing: 2px;
        color: $color-black;
        margin: 1rem auto 0;
      }
    }
  }
}
.product-full__container {
  .product-full__data {
    .product-full__ctas {
      .product-full__ctas-shaded-wrapper {
        .bag-one-click-wrapper {
          @include breakpoint($medium-up) {
            float: inherit;
          }
        }
      }
    }
  }
}

// Hide SMS optin on Account profile page.
.profile-page {
  .sms-promotions {
    display: none;
  }
}
/* eGift Card Landing - Cashstar overrides */
.egiftcard-cashstar {
  &__left {
    @include breakpoint($medium-up) {
      margin-top: 4%;
    }
  }
  &__desc {
    @include breakpoint($medium-up) {
      padding-top: 0;
    }
  }
  &__link {
    margin-top: 10px;
    @include breakpoint($medium-up) {
      @include swap_direction(margin, 5px 0 65px 3px);
    }
    @if $cr22 == false {
      letter-spacing: 0.2em;
    } @else {
      @include breakpoint($xlarge-screen) {
        margin: 10px auto 0;
      }
      @include breakpoint($xlarge-up) {
        @include swap_direction(margin, 10px 0 0 3px);
      }
    }
  }
  &__disclaimer {
    padding-bottom: 10px;
  }
  &__bottom {
    @if $cr22 == true {
      @include breakpoint($medium-only) {
        margin-top: 0;
      }
    }
  }
}
