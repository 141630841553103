// CART ITEM
// content class: .checkout__content
// sidebar class: .checkout__sidebar

.cart-item {
  // price
  &__price {
    @include breakpoint($small-down) {
      margin: 0;
      padding-top: 10px;
      line-height: 30px;
    }
  } // /price

  // total
  &__total {
    // content
    .checkout__content & {
      @include breakpoint($small-down) {
        margin: 0;
        padding-top: 10px;
        line-height: 30px;
      }
    }
  } // /total
} // /cart-item
.device-mobile {
  .cart-item {
    &__thumb {
      &-image {
        width: auto;
      }
    }
  }
}

.checkout__sidebar {
  .cart-header {
    display: none !important;
  }
}

.checkout {
  .checkout-pc-content {
    @include breakpoint($medium-up) {
      display: inline-block !important;
    }
  }
}

.pre-order-message-display {
  float: left;
  margin: 10px 0;
  width: 100%;
  font-size: 12px;
  .order-summary__content & {
    font-size: 0.8em;
  }
}

.order-details-page__content {
  @include breakpoint($medium-down) {
    .cart-item__qty {
      width: 100%;
      &-label {
        display: inline-block;
      }
    }
  }
}

.bogo-offer {
  .cart-item {
    &__remove-form,
    &__replenishment {
      display: none;
    }
    &__qty {
      padding-#{$ldirection}: 14px;
    }
    &__price {
      padding-#{$ldirection}: 3px;
    }
  }
}
.checkout__content {
  .order-summary__shipping {
    form {
      @include breakpoint($medium-up) {
        width: auto;
      }
    }
  }
}
