.account-page__content {
  .account-page__non__loyalty {
    .account-page__non__loyalty__container {
      height: 390px;
      @include swap_direction(padding, 62px 0 0 40px);
      line-height: 1;
      display: inline-block;
      background-repeat: no-repeat;
      max-width: 790px;
      .non__loyalty__panel__join {
        width: 90%;
      }
    }
  }
  .loyalty__panel__offers {
    .loyalty__panel__offers__title {
      font-size: 20px;
    }
    &__sku {
      .loyalty__panel__offers__sku__title {
        font-size: 15px;
      }
    }
  }
  .loyalty__panel__transactions__title {
    font-size: 15px;
  }
  .loyalty-page {
    &__content {
      @include breakpoint($medium-up) {
        padding: 0;
      }
      padding: 22px;
    }
  }
}

.loyalty__panel__points__info-section {
  position: relative;
  .loyalty__panel__points__info-link {
    bottom: 17px;
    #{$ldirection}: 10px;
  }
}

.loyalty__panel__points__right {
  .loyalty__panel__points__info-section {
    @include breakpoint($small-down) {
      min-height: 76px;
    }
    min-height: 140px;
  }
}

.marketing__wrapper--redesign2021 {
  .loyalty__panel__points__info-section {
    .loyalty__panel__points__info-link {
      position: absolute;
      #{$ldirection}: 0;
    }
  }
  .loyalty__panel__points__left {
    .loyalty__panel__points__stats {
      margin-top: 0;
    }
    .user_status-points_title_header {
      padding-bottom: 10px;
      padding-top: 20px;
    }
  }
  .header-text {
    .user_status-points_number {
      font-weight: bold;
    }
  }
  .loyalty__panel__points__right {
    .user_status-info_container {
      margin-top: 29px;
    }
    .user_status-info_header {
      .header-text {
        font-weight: bold;
      }
    }
  }
}

.marketing__enrollment {
  .marketing__enrollment__accept__terms {
    @include breakpoint($small-only) {
      margin-#{$ldirection}: auto;
      margin-#{$rdirection}: auto;
      padding-#{$ldirection}: 23px;
    }
  }
  .loyalty__form__error__message {
    @include breakpoint($small-only) {
      margin-#{$ldirection}: auto;
      margin-#{$rdirection}: auto;
      padding-#{$ldirection}: 19px;
    }
  }
}

.content {
  .basic-responsive {
    .earning-points__header {
      @include breakpoint($medium-up) {
        font-size: 20px;
      }
    }
  }
}

.account__section--loyalty {
  .loyalty__panel__points__lr2021 {
    .loyalty__panel__points__info-rewards-container-link {
      display: none;
    }
  }
}

.loyalty-membership-section__fieldset {
  .loyalty-membership-section {
    &__join-now {
      .form-submit {
        width: 93%;
        @include breakpoint($medium-up) {
          width: auto;
        }
      }
    }
    &__accept-terms {
      .label-content {
        @include breakpoint($medium-up) {
          width: 87%;
          padding-top: 0;
          margin-#{$ldirection}: 2px;
        }
      }
    }
  }
}

.loyalty__panel__points {
  .loyalty__panel__points__info-header {
    .header-text {
      font-size: 16px;
    }
  }
}

.loyalty__panel__points__info-container {
  .loyalty__panel__points__info-section {
    .loyalty__panel__points__info-link {
      width: 93%;
      .info-link {
        &,
        &--hyper-link {
          display: block;
        }
      }
      @include breakpoint($small-only) {
        position: absolute;
        #{$ldirection}: 4%;
      }
    }
  }
}

.checkout {
  .signin-panel--lr2021 {
    @include breakpoint($small-down) {
      margin-bottom: 30px;
    }
  }
}

.marketing__benefits__header {
  @include breakpoint($medium-up) {
    font-size: 65px !important;
  }
}

.marketing__faq__header {
  @include breakpoint($medium-up) {
    font-size: 65px !important;
  }
}

.marketing__enrollment__signup-section .marketing__enrollment__button.disabled {
  background: $color-white !important;
}

.marketing__enrollment {
  @include breakpoint($medium-up) {
    &__header {
      font-size: 70px !important;
    }
    &__subheader {
      width: 100% !important;
    }
    &__email {
      width: 360px !important;
      margin-#{$rdirection}: 5px !important;
    }
  }
}

.marketing_page_table_wrapper {
  .marketing__benefits__section__header__tier {
    max-height: 100px;
    border-bottom-width: 20px;
  }
}

.earning-points__section--redesign2021 {
  @include breakpoint($medium-down) {
    min-height: 100px;
  }
}

.button.earning-points__cta_abs {
  @include breakpoint($medium-down) {
    line-height: 0;
  }
}

.marketing__faq__link .faq-link {
  line-height: 0;
}

.loyalty-cancel-overlay {
  border: 1px solid $color-grey-border;
  #cboxContent {
    .loyalty_popover__cancel {
      .loyalty_popover {
        &__content-container {
          margin-top: 20px;
        }
        &__content__checkbox__content {
          padding-top: 4px;
        }
        &__header {
          @include breakpoint($small-down) {
            width: 60%;
            margin-top: 15px;
          }
        }
      }
    }
  }
}
