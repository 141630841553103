/* Mixin function for review star Background image */
$color-blue: #005288;

@mixin pr-background-image {
  $pr-count: (0, 25, 50, 75, 100);
  $pr-height-value: 15px;
  $pr-width-value: 15px;
  @each $pr-value in $pr-count {
    .pr-star-v4-#{$pr-value}-filled {
      background-image: url('/media/images/stars/rating-#{$pr-value}.png');
      height: $pr-height-value;
      width: $pr-width-value;
    }
  }
}
// New stars added for 2019 Redesign
@mixin pr-background-image-v2 {
  $pr-count: (0, 25, 50, 75, 100);
  $pr-height-value: 15px;
  $pr-width-value: 15px;
  @each $pr-value in $pr-count {
    .pr-star-v4-#{$pr-value}-filled {
      background-image: url('/media/images/stars_v2/rating-#{$pr-value}.png');
      height: $pr-height-value;
      width: $pr-width-value;
    }
  }
}
/* Mixin function for pr-button display */

@mixin pr-button-display {
  border-bottom: 2px solid $color-pink;
  padding: 10px 0;
  color: $color-pink;
  font-family: $body-font-bold;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  line-height: 1;
  letter-spacing: 2.5px;
}
/* Mixin function for pr-form-submit-btn button */

@mixin pr-form-submit-btn {
  color: $color-white;
  font-family: $body-font-bold;
  font-size: 14px;
  text-decoration: none;
  letter-spacing: 2px;
  text-align: center;
  padding: 14px;
  text-transform: uppercase;
  border: none;
}
/* Mixin function for review star Large Background image */
@mixin pr-large-background-image {
  $pr-count: (0, 25, 50, 75, 100);
  $pr-height-value: 15px;
  $pr-width-value: 15px;
  @each $pr-value in $pr-count {
    .pr-star-v4-#{$pr-value}-filled {
      background-image: url('/media/images/stars/rating-large-#{$pr-value}.png');
      height: $pr-height-value;
      width: $pr-width-value;
    }
  }
}
/* Common Section render for MPP, SPP, Review, Endeca search and Display Page */
#pr-write,
#pr-reviewdisplay,
.product-grid__content,
.product-full__details,
.product-full__review-snippet,
.product-starter-kit__review-snippet,
#pr-reviewsnippet,
.search-wrapper__results,
#pr-questiondisplay {
  .p-w-r {
    margin-left: 0;
    /* To display a Star background Image */
    @include pr-background-image-v2();
    .product-grid-wrapper & {
      float: left;
      margin: 0 0 15px 0;
    }
    a {
      color: $color-black;
      &:hover {
        color: $color-black;
        text-decoration: underline;
      }
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    span,
    p,
    strong {
      color: $color-black;
      font-family: $body-font;
      font-size: 14px;
      font-weight: normal;
    }
    .pr {
      &-textinput,
      &-textarea {
        @include input-placeholder {
          font-family: $body-font;
          font-size: 14px;
        }
      }
      &-alert-container,
      &-header-product-img,
      &-header-required,
      &-logo-container,
      &-header-product-name,
      &-review-snapshot-header-intro,
      &-snippet-rating-decimal {
        display: none;
      }
      &-table-cell {
        padding: 0;
      }
      &-helper-text {
        color: $color-black;
      }
      &-clear-all-radios {
        color: $color-blue;
        letter-spacing: 0;
        &:hover {
          color: $color-black;
          text-decoration: underline;
        }
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
      &-accessible-btn {
        @include pr-button-display();
      }
      &-header-table {
        .pr-header-title {
          text-transform: uppercase;
          font-weight: bold;
        }
      }
      &-snippet-stars-reco {
        &-inline {
          .pr-snippet-read-and-write {
            display: inline-block;
            a,
            span {
              color: $color-black;
              font-family: $body-font;
              font-size: 14px !important;
              letter-spacing: normal;
              line-height: 1;
              vertical-align: top;
              text-transform: capitalize;
            }
            a:hover {
              color: $color-gray-dark;
            }
          }
          .pr-snippet-stars-container {
            vertical-align: middle;
          }
          &.pr-snippet-minimal {
            .pr-snippet-read-and-write {
              .pr-snippet-review-count {
                margin: 0 10px 0 10px;
                padding: 0 0px 0 0px;
              }
              a {
                text-decoration: none;
              }
              a:hover {
                color: $color-black;
                text-decoration: underline;
              }
            }
          }
        }
      }
      /* To display a Review description posted by user */
      &-review-display {
        dt,
        dd,
        dl {
          color: $color-black;
          font-family: $body-font;
          font-size: 14px;
        }
        .pr-review {
          .pr-rd-header {
            .pr-rd-review-headline {
              color: $color-black;
              font-family: $body-font-bold;
              font-size: 18px;
              font-weight: normal;
              text-transform: uppercase;
            }
          }
        }
        .pr-rd-description-text,
        .pr-rd-details {
          color: $color-black;
          font-family: $body-font;
          font-size: 14px;
        }
        .pr-rd-author-nickname {
          font-family: $body-font-bold;
          font-size: 14px;
          text-transform: capitalize;
          .pr-rd-bold {
            display: none;
          }
        }
        .pr-rd-author-submission-date {
          time {
            color: $color-black;
          }
        }
        .pr-rd-footer {
          padding: 0 0 10px 0;
        }
        .pr-rd-main-header {
          background: none;
          padding: 0 0 40px;
          h1,
          label {
            display: none;
          }
          .pr-rd-sort-group {
            @include breakpoint($small-down) {
              text-align: center;
            }
          }
          .pr-rd-sort {
            font-family: $body-font;
            font-size: 14px;
            color: $color-black;
            min-width: 175px;
            -webkit-appearance: menulist;
            background: $color-white;
            @include breakpoint($small-down) {
              background: $color-white;
              -webkit-appearance: menulist;
              width: 50%;
              margin-top: 15px;
            }
          }
        }
        .pr-rd-description {
          color: $color-black;
          font-family: $body-font;
          font-size: 14px;
          @include breakpoint($medium-portrait-only) {
            width: 70%;
          }
        }
      }
    }
    /* For Review Thank You page */
    .thank-you-page {
      h2.headline,
      h1.title {
        color: $color-black;
        font-family: $body-font;
        font-size: 18px;
        font-weight: normal;
      }
      h2.headline {
        text-transform: uppercase;
      }
      .pr-rating {
        &-number,
        &-date {
          border: none;
          padding: 0px 4px 1px;
        }
      }
      .subtitle {
        color: $color-black;
        font-size: 14px;
      }
      .title {
        color: $color-black;
        text-transform: capitalize;
      }
      .pr-dark {
        color: $color-black;
        font-weight: bold;
      }
    }
    .pr-btn-review {
      background-color: $color-black;
      @include pr-form-submit-btn();
    }
    .pr-btn-review:hover {
      background-color: $color-pink;
      color: $color-white;
    }
    .pr-rid {
      &-btn-container {
        .pr-ellipsis-icon {
          background-color: $color-black;
          border: 1px solid $color-black;
          &__circle__inner {
            stroke: $color-black;
          }
        }
        &:hover {
          .pr-ellipsis-icon {
            background-color: $color-white;
            &__circle__inner {
              color: $color-white;
              fill: $color-black;
            }
          }
        }
        .pr-rid-btn-text {
          color: $color-black;
          font-family: $body-font;
        }
      }
    }
  }
}

#pr-war-form {
  .pr-cons-form-group,
  .pr-bestuses-form-group,
  .pr-pros-form-group,
  .pr-wasthisagift-form-group {
    display: none;
  }
  .form-group {
    margin-bottom: 25px;
    .tag-group {
      .pr-label-control {
        &.checked {
          background-color: $color-black;
          span {
            color: $color-white;
          }
        }
        span {
          float: none;
          color: $color-black;
        }
      }
    }
    input {
      padding: 5px 10px !important;
    }
  }
  .pr-media_image-form-group,
  .pr-media_videourl-form-group {
    label {
      color: $color-white;
      background-color: $color-black;
      border-color: $color-black;
      padding: 6px;
      width: 195px !important;
      @include breakpoint($small-down) {
        width: 180px !important;
      }
      &:hover {
        background-color: $color-pink;
        border-color: $color-pink;
      }
      span {
        color: $color-white;
        float: none;
      }
    }
    .pr-icon-delete {
      padding: 2px 2px;
      span {
        float: none;
      }
    }
  }
  .tag-group {
    .pr-btn-add-tag {
      a {
        color: $color-white;
        background-color: $color-black;
        border-color: $color-black;
        padding: 9px 9px 9px 5px;
        width: auto !important;
        text-decoration: none;
        font-family: $body-font;
        font-size: 14px;
        text-transform: uppercase;
        &:hover {
          background-color: $color-pink;
          border-color: $color-pink;
        }
        &:after {
          color: $color-white;
        }
      }
    }
    .input-add-tag {
      width: 93%;
      @include breakpoint(($small-down) (orientation portrait)) {
        width: 87%;
      }
      span {
        float: none;
      }
    }
  }
}

#power_review_container {
  float: left;
  width: 100%;
  padding: 0 10px;
  .product-full {
    &__container {
      max-width: 1024px;
      margin: 0 auto;
      padding: 40px 0 20px;
      position: relative;
    }
    &__image {
      width: 35%;
      margin-right: 5%;
      float: left;
      @include breakpoint($small-down) {
        width: 100%;
        text-align: center;
        margin-right: 0;
      }
    }
    &__details {
      float: left;
      width: 60%;
      @include breakpoint($small-down) {
        width: auto;
      }
      .product-full__name {
        color: $color-black;
        font-family: $body-font-bold;
        font-size: 18px;
        text-transform: uppercase;
        font-weight: normal;
        margin-bottom: 5px;
      }
      .js-product-image-link {
        color: $color-white;
      }
    }
    &__image {
      img {
        margin-bottom: 10px;
        float: left;
        padding-top: 24%;
        @include breakpoint($small-down) {
          width: 190px;
          float: none;
          padding-top: 0;
        }
      }
    }
  }
  .p-w-r {
    .thank-you-page {
      .col-sm-12 {
        .pr-rating-number {
          border: none;
          span {
            float: left;
          }
        }
        span {
          float: none;
        }
      }
    }
    .pr-media-preview {
      .pr-loading-indicator {
        @include breakpoint(($small-down) (orientation portrait)) {
          width: 35px;
          height: 35px;
        }
      }
    }
    .pr-btn-danger {
      @include breakpoint(($small-down) (orientation portrait)) {
        padding: 2px 5px;
      }
    }
    fieldset {
      width: auto;
    }
    .pr-header {
      border-bottom: 0;
      @include breakpoint($small-down) {
        position: absolute;
        top: 10px;
        width: 100%;
      }
    }
    .pr-header-title {
      margin: 0;
    }
    span {
      font-size: 14px;
      line-height: 1.5;
      color: $color-black;
      font-weight: normal;
      text-transform: uppercase;
      display: block;
      float: left;
      font-family: $body-font;
    }
    .pr-label-control.checked {
      span {
        float: none;
        color: $color-white;
      }
    }
    .input-add-tag {
      .pr-btn-add-tag-submit {
        padding: 7px 7px;
        height: 32px;
      }
    }
    input,
    select {
      height: 32px !important;
      color: $color-black;
      font-size: 14px;
      letter-spacing: normal;
      padding: 5px 10px;
      font-family: $body-font;
    }
    select {
      max-width: 100%;
      padding: 3px !important;
      -webkit-appearance: menulist;
    }
    textarea {
      font-family: $body-font;
    }
    .pr-war {
      max-width: 1024px;
      .pr-submit {
        .pr-accessible-btn {
          font-family: $body-font;
          margin: 25px 0 50px 0;
        }
        .pr-subscript {
          font-family: $body-font;
          line-height: 16px;
          font-size: 14px;
          text-transform: none;
        }
        .pr-subscript a {
          text-decoration: underline;
          font-family: $body-font;
          font-size: 14px;
        }
      }
    }
    .pr-header-title {
      color: $color-black;
      font-family: $body-font;
      font-size: 18px;
      line-height: normal;
      display: block;
      font-weight: bold;
      border-bottom: 2px solid $color-black;
      padding-bottom: 15px;
    }
    #pr-war-form {
      .form-group {
        .pr-control-label {
          span {
            font-weight: bold;
          }
          @include breakpoint($small-down) {
            width: 100% !important;
          }
        }
        .pr-helper-text {
          font-family: $body-font;
          text-transform: none;
        }
        .pr-clear-all-radios {
          color: $color-gray-dark;
          font-family: $body-font;
        }
        input::placeholder {
          font-family: $body-font;
          font-size: 14px;
          line-height: 1.5;
        }
        #pr-bottomline {
          .pr-control-label {
            width: 100% !important;
          }
          .btn-group-2-count {
            .pr-btn {
              font-family: $body-font;
              &.active {
                background: $color-black;
              }
            }
            @include breakpoint($small-down) {
              width: 100%;
            }
          }
          .pr-helper-text {
            display: none;
          }
        }
        .btn-group {
          .pr-btn {
            padding: 8px 10px;
            @include icon('radio', before);
            &.active,
            &:hover {
              @include icon('radio_checked', before, false, false, 14px, $color-white);
            }
            &.active,
            &:hover {
              background: $color-black;
              color: $color-white;
            }
          }
        }
        .pr-btn-danger {
          background-color: $color-black;
          border-color: $color-black;
          &:hover {
            background-color: $color-pink;
            border-color: $color-pink;
          }
        }
      }
      .pr-email_collection-form-group,
      .pr-loyaltymember2-form-group,
      .pr-bbclubmember2-form-group {
        display: none;
      }
      .pr-skinconcerns-form-group,
      .pr-skinconcern-form-group,
      .pr-productbenefits-form-group {
        .pr-control-label {
          float: left !important;
          margin-right: 20px;
        }
      }
      .form-group {
        &.pr-has-error {
          input {
            &.form-control {
              border-color: $color-red;
            }
          }
          .pr-control-label {
            span {
              color: $color-red;
            }
          }
        }
        .pr-control-label {
          @include breakpoint($small-down) {
            span {
              display: inline;
              float: none;
            }
          }
        }
      }
      .form-group {
        &.pr-rating-form-group {
          label {
            &:before {
              content: none;
            }
            color: $color-black;
            float: none !important;
            span {
              color: $color-black;
            }
          }
        }
      }
    }
  }
  #pwr_signin {
    @include breakpoint($small-down) {
      padding-top: 0;
    }
  }
  .pr_prod_img {
    width: 50%;
    float: $ldirection;
    @include breakpoint($small-down) {
      width: 100%;
      float: none;
      text-align: center;
    }
  }
  .email_check {
    width: 45%;
    float: $ldirection;
    h3 {
      margin-bottom: 20px;
      font-size: 17px;
      font-family: $body-font;
      color: $color-black;
    }
    h2 {
      font-size: 21px;
      color: $color-black;
      font-family: $body-font;
    }
    .pr_email {
      margin-bottom: 20px;
    }
    .error {
      margin-bottom: 15px;
      color: $color-red;
    }
    @include breakpoint($small-down) {
      width: 100%;
      float: none;
    }
  }
  .full_sign_in {
    float: $ldirection;
    width: 45%;
    h2 {
      font-size: 21px;
      color: $color-black;
      font-family: $body-font;
      &:nth-child(2) {
        font-size: 17px;
        margin-bottom: 20px;
      }
    }
    .pr_row_email {
      margin-bottom: 15px;
    }
    .pr-row_password {
      .form-text {
        float: $ldirection;
        margin-#{$rdirection}: 10px;
        @include breakpoint($small-down) {
          float: none;
        }
        @include breakpoint(($small-down) (orientation landscape)) {
          float: $ldirection;
        }
      }
      div {
        padding-top: 4px;
      }
    }
    .pr_row_submit {
      clear: both;
      padding-top: 15px;
    }
    .error {
      margin-bottom: 15px;
      color: $color-red;
    }
    @include breakpoint($small-down) {
      width: 100%;
      float: none;
    }
  }
  &.chat-power-review-container {
    .sign-in-page {
      margin: 40px auto;
      .pr_prod_img {
        display: none;
      }
      .email_check,
      .full_sign_in,
      .social-login {
        float: none;
        margin: 0 auto;
        padding-#{$rdirection}: 0;
        @include breakpoint($medium-up) {
          width: 50%;
        }
      }
      .social-login {
        &__email-container,
        &__email-opt-in {
          float: $ldirection;
          margin-top: 14px;
        }
      }
    }
    .product-full {
      &__name {
        font-family: $bb-sans;
        font-size: 25px;
      }
      &__image {
        display: none;
      }
      &__details {
        float: none;
        margin: 0 auto;
      }
    }
    .write_a_review__container {
      .p-w-r {
        .pr-header {
          position: static;
        }
        .pr-header-title {
          font-weight: normal;
          text-align: $ldirection;
          text-transform: lowercase;
          &::first-letter {
            text-transform: uppercase;
          }
        }
        #pr-war-form {
          .pr-rating-stars {
            @include breakpoint($medium-up) {
              padding-top: 20px;
            }
            @include breakpoint($xxlarge-range) {
              padding-top: 0;
            }
          }
          .pr-rating-form-group {
            margin-bottom: 10px;
          }
          .pr-label-control {
            padding: 20px;
            span {
              display: inline-block;
              margin-bottom: 2px;
              vertical-align: middle;
            }
          }
          .pr-control-label,
          .pr-label-control {
            span {
              display: inline-block;
              font-size: 15px;
              font-weight: normal;
              text-transform: lowercase;
              &::first-letter {
                text-transform: uppercase;
              }
            }
          }
          .pr-helper-text {
            display: inline-block;
            font-size: 15px;
            font-weight: normal;
            line-height: 1.5;
            vertical-align: bottom;
          }
          .pr-btn-add-tag a {
            font-weight: bold;
            padding: 9px 15px;
          }
          .pr-media_image-form-group,
          .pr-media_videourl-form-group {
            .pr-file-input-label {
              background-color: $color-white;
              border-color: $color-white;
              color: $color-black;
              width: auto !important;
            }
          }
          .pr-pros-form-group,
          .pr-productbenefits-form-group,
          .pr-skinconcerns-form-group,
          .pr-skinconcern-form-group {
            legend {
              &.pr-control-label,
              &.pr-label-control {
                display: inline-flex;
                float: none !important;
                span {
                  margin-#{$rdirection}: 5px;
                }
              }
            }
          }
          .pr-pros-form-group {
            display: block;
          }
          .pr-bottomline-form-group {
            span {
              margin-#{$rdirection}: 5px;
            }
          }
        }
        .pr-subscript,
        .pr-subscript a {
          font-size: 13px;
        }
        .pr-btn-review {
          font-weight: bold;
          letter-spacing: 0;
        }
      }
    }
  }
}

.site-footer {
  clear: both;
}

#pr-write {
  .p-w-r {
    .pr-table-cell:nth-child(2) {
      @include breakpoint($small-down) {
        display: block;
      }
      h3 {
        @include breakpoint($small-down) {
          text-align: center;
        }
      }
    }
    .thank-you-page {
      text-transform: none;
      .title,
      .subtitle,
      h5,
      .pr-rating-date {
        font-family: $body-font;
      }
      .title {
        margin-top: 0;
      }
      .header {
        margin-top: 0;
        h5 {
          a {
            text-decoration: underline;
          }
        }
      }
      #loyaltymember2,
      #bbclubmember2 {
        display: none;
      }
      .review-preview {
        .pr-text {
          font-size: 14px;
          span {
            text-transform: none;
            display: inline-block;
          }
        }
      }
    }
    .form-group {
      .pr-btn-default {
        span:after {
          color: $color-white;
        }
      }
    }
    .pr-footer {
      .pr-accessible-btn {
        font-size: 14px;
        font-weight: normal;
        padding: 9px 15px;
      }
      a {
        font-size: 12px;
        text-decoration: none;
      }
    }
    #pr-alert {
      display: block;
      h4,
      p {
        font-family: $body-font;
        line-height: 20px;
        text-transform: none;
      }
    }
  }
}
//Favourites page

.favorites__list {
  .product-full__review-snippet,
  .product-starter-kit__review-snippet {
    .p-w-r {
      .pr-category-snippet {
        margin: 0;
      }
      .pr-category-snippet__total {
        padding-top: 4px;
      }
    }
  }
}

//Viewcart page

.viewcart {
  .recommended-product-items {
    .product-full__review-snippet,
    .product-starter-kit__review-snippet {
      .pr-category-snippet {
        margin: 0;
        .pr-category-snippet__total {
          display: none;
        }
      }
    }
  }
}

#pr-reviewdisplay {
  .p-w-r {
    .pr-review-snapshot-block-container,
    .pr-review-snapshot-faceoff,
    .pr-review-snapshot-msq-container {
      display: none;
    }
    .pr-review-snapshot {
      background-color: $color-gray-background;
      .spp-product-layout__content-item & {
        background-color: $color-white;
      }
      .pr-review-snapshot-content-block {
        margin-bottom: 0px;
      }
      .pr-snippet-stars-container,
      .pr-snippet-reco-to-friend {
        display: none;
      }
      .pr-snippet-read-and-write {
        @include breakpoint($small-down) {
          margin: 0 auto;
          width: 100%;
          text-align: center;
        }
        .pr-snippet-review-count {
          display: none;
        }
        .pr-snippet-write-review-link {
          position: relative;
          top: 20px;
          @include pr-button-display();
          @include breakpoint($small-down) {
            position: relative;
            top: 10px;
            left: 4px;
          }
        }
        .pr-snippet-write-review-link:hover {
          color: $color-black;
          border-bottom: 2px solid $color-black;
        }
      }
    }
    .pr-review-snapshot-block-container {
      @include breakpoint($small-down) {
        margin-top: 80px;
      }
    }
    .pr-rating-stars {
      padding-bottom: 0px;
      margin-top: 3px;
    }
    .pr-review-display {
      background-color: $color-gray-background;
      .spp-product-layout__content-item & {
        background-color: $color-white;
      }
      @include breakpoint($small-down) {
        margin-top: 10px;
      }
      .pr-rd-footer,
      .pr-snippet-stars {
        background-color: $color-gray-background;
        .spp-product-layout__content-item & {
          background-color: $color-white;
        }
      }
      .pr-rd-footer {
        @include breakpoint($small-down) {
          padding-bottom: 15px;
          height: auto;
          margin-bottom: 5px;
        }
        .pr-rd-bottomline {
          margin-bottom: 5px;
          .pr-rd-bold {
            display: none;
          }
        }
        .pr-rd-helpful-action {
          margin-right: 5px;
          @include breakpoint($small-down) {
            padding: 8px 8px 0 0;
            margin-right: 0;
            margin-bottom: 16px;
          }
        }
      }
      .pr-helpful {
        &-count {
          font-size: 14px;
          text-transform: uppercase;
          padding-right: 3px;
          line-height: 20px;
          color: $color-black;
          font-weight: bold;
          &:hover {
            color: $color-black;
          }
        }
        &-btn {
          border: 0;
          padding: 0 1px;
          background: none;
          text-transform: capitalize;
          vertical-align: top;
          font-weight: bold;
          @include breakpoint($small-down) {
            margin: 15px 0px;
            float: left;
            padding: 0;
          }
          .pr-thumbs-icon {
            display: none;
          }
        }
        &-yes {
          .pr-helpful-count {
            &:before {
              content: 'yes';
              visibility: visible;
              margin-right: 5px;
              text-transform: uppercase;
              font-size: 14px;
            }
            &:after {
              content: '|';
              margin: 0 3px 0 10px;
            }
          }
        }
        &-no {
          .pr-helpful-count {
            &:before {
              content: 'no';
              visibility: visible;
              margin-right: 5px;
              text-transform: uppercase;
              font-size: 14px;
            }
            &:after {
              content: '|';
              margin: 0 0 0 9px;
            }
          }
        }
      }
      .pr-rd-flag-review-container {
        vertical-align: bottom;
        a {
          color: $color-black;
          font-family: $body-font;
          font-size: 14px;
          @include breakpoint($small-down) {
            margin-left: 10px;
          }
        }
        .pr-flag-icon {
          margin-top: -4px;
          float: left;
        }
        .pr-flag-review-thankyou {
          display: inline;
        }
      }
      .pr-review {
        border-bottom: 1px solid $color-white;
        margin-bottom: 30px;
        .spp-product-layout__content-item & {
          border-bottom-color: $color-gray-border;
        }
        @include breakpoint($small-down) {
          margin-bottom: 15px;
        }
        .pr-rd-images {
          &.pr-rd-content-block {
            display: none;
          }
        }
        .pr-rd-side-content-block {
          &.pr-rd-right {
            position: absolute;
            top: -40px;
            right: -40%;
            width: 280px;
            @include breakpoint($small-down) {
              position: relative;
              top: 0;
              right: 0;
            }
            @include breakpoint($medium-portrait-only) {
              width: 30%;
            }
            .pr-rd-reviewer-type {
              &.pr-verified_reviewer {
                display: none;
              }
              &.pr-staff_reviewer {
                .pr-badging-icon {
                  height: auto;
                  width: auto;
                }
                svg {
                  display: none;
                }
                &:before {
                  content: url('/media/images/stars/bobbi_badge.png');
                  height: 20px;
                  width: 20px;
                }
              }
            }
          }
        }
        button {
          outline: 0;
          box-shadow: none;
        }
        .pr-rd-review-headline {
          width: 80%;
        }
      }
      .pr-rd-main-header {
        @include breakpoint($small-down) {
          margin-top: 2px;
          padding-bottom: 0;
        }
      }
      .pr-rd-main-footer {
        border-bottom: 1px solid $color-light-gray;
        border-top: 1px solid $color-light-gray;
        padding: 10px 0;
        .pr-rd-content-block {
          width: 100%;
        }
        .pr-rd-pagination {
          .pr-rd-pagination-btn {
            color: $color-black;
            font-family: $body-font;
            text-transform: inherit;
          }
          @include breakpoint($small-down) {
            padding: 0;
            margin: 0;
            border: 0;
          }
        }
        .pr-rd-to-top {
          padding-left: 31%;
          text-align: center;
          font-size: 14px;
          font-family: $body-font;
          @include breakpoint($small-down) {
            text-align: center;
            padding: initial;
          }
        }
      }
      .pr-rd-merchant-response {
        background-color: $color-gray-background;
      }
      dt {
        font-weight: bold;
        background: transparent;
      }
      .pr-rd-no-reviews {
        .pr-snippet-write-review-link {
          font-family: $body-font;
          text-decoration: underline;
        }
      }
      .pr-rd-content-block {
        &.pr-rd-subratings {
          margin-bottom: 15px;
          @include breakpoint($small-down) {
            margin-bottom: 15px;
          }
        }
      }
      .pr-rd-header {
        .pr-rd-star-rating {
          float: none;
          line-height: 25px;
        }
        .pr-rd-review-headline {
          margin-left: 0;
        }
      }
    }
    .pr-modal-content {
      .pr-flag-review-btn {
        @include pr-form-submit-btn();
      }
      .pr-flag-review-btn-submit {
        background-color: $color-pink;
      }
      .pr-flag-review-btn-cancel,
      .pr-flag-review-btn:hover {
        background-color: $color-black;
        color: $color-white;
      }
      .pr-control-label,
      .pr-flag-review-label {
        color: $color-black;
        font-family: $body-font;
        font-size: 14px;
      }
    }
    .pr-accordion {
      @include breakpoint($small-down) {
        margin-bottom: 0;
      }
      .pr-accordion-btn {
        span {
          font-weight: bold;
        }
        .pr-caret-icon__line {
          stroke: $color-black;
        }
      }
    }
  }
}

// Redesign 2019 - power reviews display overrides
#pr-reviewdisplay .p-w-r {
  @include breakpoint($portrait-up) {
    margin-#{$rdirection}: 4px;
  }
  .pr-star-v4 {
    width: 25px;
    height: 25px;
  }
  .pr-review-snapshot {
    display: block;
    @include breakpoint($portrait-up) {
      display: flex;
    }
    position: relative;
    .pr-snippet-reco-to-friend {
      display: block;
    }
    .pr-review-snapshot-block {
      min-height: auto;
      margin-#{$rdirection}: 0;
      &.pr-review-snapshot-block-pros dt h1 {
        background-color: transparent;
      }
      &.pr-review-snapshot-block-cons {
        display: none;
      }
      &.pr-review-snapshot-block-pros {
        border: none;
        margin-top: 50px;
        @include breakpoint($portrait-up) {
          width: 33.33%;
          margin-top: 0;
        }
        .pr-snapshot-highlight {
          display: none;
        }
        dt {
          background-color: transparent;
          padding-top: 0;
        }
        .pr-snapshot-tag-def:first-of-type {
          padding-top: 5px;
        }
      }
      .pr-snapshot-tag-def {
        width: 50%;
        display: inline-block;
        @include breakpoint($portrait-up) {
          width: 100%;
          display: block;
        }
        padding: 0;
        padding-#{$ldirection}: 10px;
      }
    }
    .pr-ratings-histogram {
      .pr-ratings-histogram-barValue {
        background-color: $color-black;
      }
      .pr-histogram-stars {
        height: 50px;
        vertical-align: top;
        &:last-child {
          height: 27px;
        }
        .pr-histogram-label {
          font-weight: bold;
          vertical-align: top;
          line-height: 27px;
        }
        .pr-histogram-count {
          vertical-align: top;
          line-height: 27px;
        }
      }
      .pr-ratings-histogram-bar-container {
        vertical-align: top;
      }
    }
    .pr-snippet-stars-container {
      display: block;
      .pr-accessible-text {
        width: auto;
        height: auto;
        position: relative;
        margin: 10px 0;
        font-size: 13px;
        @include breakpoint($portrait-up) {
          font-size: 14px;
        }
      }
    }
    .pr-review-snapshot-content-block {
      width: 100%;
      display: block;
      @include breakpoint($portrait-up) {
        width: 25%;
        display: inline-flex;
      }
    }
    .pr-review-snapshot-block-container .pr-review-snapshot-block-histogram {
      width: auto;
      @include breakpoint($portrait-up) {
        width: 66.66%;
      }
      border: none;
      .pr-review-snapshot-block-headline {
        display: none;
      }
      .pr-ratings-histogram.pr-histogram-list {
        margin: 0;
      }
    }
    .pr-snippet-read-and-write .pr-snippet-write-review-link {
      position: relative;
      top: auto;
      bottom: auto;
      border: none;
      background-color: $color-black;
      vertical-align: middle;
      margin-#{$ldirection}: 10px;
      padding: 10px 15px;
      color: $color-white;
      &:hover {
        color: $color-white;
        background-color: $color-cta-hover;
      }
    }
  }
  .pr-review-snapshot pr-snapshot-mobile {
    display: block;
  }
  .pr-review-snapshot-block-container {
    width: 100%;
    display: block;
    @include breakpoint($portrait-up) {
      width: 75%;
    }
  }
  .pr-snippet-stars-reco-inline {
    .pr-snippet-read-and-write {
      position: absolute;
      #{$rdirection}: 0;
      top: 390px;
      overflow: hidden;
      line-height: 40px;
      @include breakpoint($portrait-up) {
        width: 25%;
        bottom: 20px;
        top: auto;
      }
    }
    .pr-snippet-stars-reco-reco {
      float: #{$rdirection};
      margin-top: 30px;
      width: 47%;
      @include breakpoint($portrait-up) {
        float: none;
        margin-top: 10px;
        width: auto;
      }
      .pr-snippet-reco-to-friend .pr-snippet-reco-to-friend-percent .pr-checkbox-icon svg {
        display: none;
      }
      .pr-reco-to-friend-message {
        text-indent: 36px;
      }
      .pr-reco .pr-reco-value {
        font-weight: bold;
        line-height: normal;
      }
      .pr-snippet-reco-to-friend-percent {
        display: inline;
        position: absolute;
      }
    }
    .pr-snippet-stars-reco-stars {
      float: #{$ldirection};
      width: 52%;
      @include breakpoint($portrait-up) {
        float: none;
        width: auto;
      }
    }
    &.pr-snippet-minimal .pr-snippet-read-and-write a:hover {
      color: $color-white;
      text-decoration: none;
    }
  }
  .pr-rd-main-header .pr-rd-sort-group {
    padding: 0;
    float: #{$ldirection};
  }
  .pr-review-display {
    .pr-review:first-of-type {
      border: 1px solid $color-gray-border;
    }
    .pr-review {
      padding: 15px;
      .pr-rd-images.pr-rd-content-block {
        display: block;
      }
      .pr-rating-stars .pr-star-v4 {
        width: 15px;
        height: 15px;
      }
      .pr-accordion-btn span,
      .pr-helpful-count:before {
        text-decoration: underline;
        color: $color-black;
        font-weight: normal;
      }
      .pr-helpful-count {
        font-weight: normal;
      }
    }
  }
}

.responsive-tabs--powerreviews {
  .resp-tabs-list.clearfix {
    display: inline-flex;
  }
  .resp-tabs-container {
    padding: 20px;
    @include breakpoint($portrait-up) {
      padding: 20px 0;
      .resp-tab-content {
        padding: 0 0 20px;
      }
    }
    .resp-accordion {
      display: none;
    }
  }
}

.product-grid__content {
  .p-w-r {
    .pr-snippet {
      margin: 0;
      .pr-snippet-stars-png {
        .pr-star-v4-0-filled,
        .pr-star-v4-25-filled,
        .pr-star-v4-50-filled,
        .pr-star-v4-75-filled,
        .pr-star-v4-100-filled {
          width: 15px;
          height: 15px;
        }
      }
      .pr-category-snippet__total {
        display: none;
      }
    }
    .pr-category-snippet__item {
      @include breakpoint($small-down) {
        display: block;
        padding-left: 0;
      }
    }
  }
  .product-thumb__headline {
    @include breakpoint($small-down) {
      height: auto;
    }
    .product__favorite {
      @include breakpoint($small-down) {
        display: none;
      }
    }
  }
}

.bobbis-beauty-corner {
  .product-grid--carousel {
    .product-grid__item {
      .product-full__review-snippet,
      .product-starter-kit__review-snippet {
        .pr-snippet {
          display: block;
        }
      }
    }
  }
}

.write_a_review__container {
  margin-top: 25px;
  #pr-write {
    .pr-header {
      .table-cell {
        padding: 10px 0;
        a {
          display: none;
        }
      }
    }
    @include pr-large-background-image();
    .pr-question-form {
      .pr-submit {
        .pr-accessible-btn {
          margin: 30px 0;
          a {
            @include pr-form-submit-btn();
          }
        }
      }
      .pr-control-label {
        span {
          font-weight: bold;
        }
      }
      .pr-waq-legal-text {
        span {
          color: $color-black;
          font-family: $body-font;
          font-size: 14px;
          line-height: 15px;
          text-transform: none;
          a {
            color: $color-black;
            font-family: $body-font;
            text-decoration: underline;
            text-transform: none;
          }
        }
      }
      #questionType {
        display: none;
      }
    }
    .p-w-r {
      .pr-waqwaa {
        .pr-header {
          #power_review_container & {
            position: relative;
          }
          .pr-header-table {
            .pr-header-title,
            span {
              text-transform: none;
            }
          }
          .pr-question-subheader {
            span {
              text-transform: uppercase;
              width: 100%;
              margin-bottom: 35px;
            }
          }
          & + p {
            @include breakpoint($small-down) {
              margin-bottom: 35px;
            }
            a {
              background-color: $color-black;
              @include pr-form-submit-btn();
              padding: 10px;
              color: $color-white;
              &:hover {
                background-color: $color-pink;
                color: $color-white;
              }
            }
          }
        }
      }
    }
  }
}

.write_a_review__container,
#pr-questiondisplay {
  .pr-question-form,
  .pr-answer-form {
    .pr-control-label {
      span {
        color: $color-black;
        font-family: $body-font;
        font-size: 14px;
        font-weight: bold;
      }
    }
    .pr-has-error {
      .pr-control-label {
        span {
          color: $color-red;
        }
      }
    }
    .btn-group {
      .pr-btn {
        padding: 8px 15px;
        font-family: $body-font;
        font-size: 14px;
        &.active {
          background-color: $color-black;
        }
      }
    }
    .form-group {
      .pr-helper-text {
        color: $color-black;
        font-family: $body-font;
        font-size: 14px;
      }
      input {
        height: 32px !important;
        color: $color-black;
        font-family: $body-font;
        font-size: 14px;
        letter-spacing: normal;
        padding: 5px 10px;
      }
    }
    .pr-waq-legal-text {
      span {
        color: $color-black;
        font-family: $body-font;
        font-size: 14px;
        line-height: 15px;
        width: 100%;
        a {
          color: $color-black;
          font-family: $body-font;
          text-decoration: underline;
        }
      }
    }
    .pr-waq-prequestion-message {
      .input_label {
        color: $color-black;
        font-family: $body-font;
        font-size: 22px;
      }
      .prfieldwrap {
        p {
          color: $color-black;
          font-family: $body-font;
          font-size: 18px;
        }
        li {
          font-family: $body-font;
          font-size: 18px;
          margin-bottom: 10px;
          color: $color-black;
          a {
            font-family: $body-font-bold;
            font-size: 18px;
            color: $color-black;
            text-transform: uppercase;
            line-height: 25px;
          }
        }
      }
    }
    .pr-submit {
      a {
        @include pr-form-submit-btn();
      }
    }
  }
}

#pr-flag-reviews {
  input[type='radio'] {
    left: auto;
  }
}

.product-full__review-snippet,
.product-starter-kit__review-snippet #pr-questionsnippet {
  display: inline-block;
  vertical-align: middle;
}

.product-full__review-snippet,
.product-starter-kit__review-snippet {
  .p-w-r {
    .pr-snippet-stars-reco-inline.pr-snippet-minimal {
      .pr-snippet {
        .pr-snippet-stars-container,
        .pr-snippet-read-and-write {
          display: inline-block;
          @include breakpoint(($small-down) (orientation portrait)) {
            vertical-align: middle;
          }
        }
        .pr-snippet-stars-container {
          vertical-align: top;
          cursor: pointer;
        }
        .pr-snippet-read-and-write {
          @include breakpoint($small-down) {
            margin-top: 0;
          }
        }
      }
    }
    .pr-snippet-stars-reco-inline.pr-snippet-compact {
      .pr-snippet {
        .pr-snippet-read-and-write {
          margin-top: 6px;
          @include breakpoint($small-down) {
            margin-top: 0px;
          }
        }
      }
    }
  }
}

.product-full__review-snippet,
.product-starter-kit__review-snippet {
  .p-w-r {
    .pr-snippet-stars-reco-inline.pr-snippet-minimal,
    .pr-snippet-stars-reco-inline.pr-snippet-compact {
      .pr-snippet-read-and-write {
        .pr-snippet-write-review-link {
          margin: 0;
          padding: 0 0 0 10px;
          border-left: 1px solid black;
        }
        @include breakpoint($small-down) {
          margin-left: 0;
          display: inline-block;
          font-size: 12px;
          .pr-snippet-review-count {
            margin: 5px 0 0 10px;
          }
          .pr-snippet-write-review-link {
            margin: 3px 0 0 0px;
            padding: 0 0 0 10px;
            display: none;
          }
          a,
          span {
            display: inline-block;
          }
        }
      }
      .pr-snippet {
        @include breakpoint($small-down) {
          padding: 0 0 5px 20px;
          .pr-snippet-stars-container {
            display: inline-block;
            vertical-align: top;
          }
        }
      }
    }
    .pr-snippet-stars-reco-inline {
      &.pr-snippet-compact {
        .pr-snippet {
          .pr-snippet-read-and-write {
            margin-top: 10px;
            @include breakpoint($small-down) {
              margin-top: 0;
            }
          }
        }
      }
    }
    .pr-review-snippet-container {
      .pr-snippet-stars-reco-inline {
        .pr-snippet-stars-reco-reco {
          display: none;
        }
      }
    }
    .pr-snippet {
      padding: 8px 0;
      .pr-snippet-stars-png {
        @include pr-background-image-v2();
      }
    }
    .pr-no-reviews {
      .pr-snippet-stars-reco-inline {
        &.pr-snippet-minimal,
        &.pr-snippet-compact {
          .pr-snippet-review-count {
            display: none;
          }
          .pr-snippet-write-review-link {
            margin-left: 0px;
            border: 0;
            @include breakpoint($small-down) {
              display: inline-block;
              padding-top: 2px;
            }
            @include breakpoint(($small-down) (orientation portrait)) {
              padding-left: 0px;
            }
          }
          .pr-snippet-read-and-write {
            margin-top: 7px;
            @include breakpoint($small-down) {
              margin-top: 0;
            }
          }
          .pr-snippet-stars-container {
            @include breakpoint(($small-down) (orientation portrait)) {
              display: none;
            }
          }
        }
        &.pr-snippet-compact {
          .pr-snippet {
            .pr-snippet-read-and-write {
              margin-top: 10px;
              @include breakpoint($small-down) {
                margin-top: 0px;
              }
            }
          }
        }
      }
    }
  }
}
/* Ask Question Section */

#pr-questionsnippet {
  .p-w-r {
    margin: 0px;
    a:focus {
      outline: none;
      box-shadow: none;
    }
    .pr-snippet-qa {
      .pr-qa-large {
        display: none;
      }
      &.pr-snippet-qa-empty {
        .pr-ask-question-link {
          display: block;
          margin: 2px 0 0 0;
          border-left: 1px solid black;
          padding: 0 0 0 10px;
          @include breakpoint($small-down) {
            margin: 0;
            padding: 0 0 0 10px;
            line-height: 14px;
          }
        }
      }
      .pr-answered-question-circle {
        background-color: $color-black;
        margin: 0;
        display: none;
      }
      .pr-snippet-qa-answer-link {
        color: $color-black;
        font-family: $body-font;
        font-size: 14px;
        letter-spacing: normal;
        text-transform: capitalize;
        border-left: 1px solid black;
        padding: 0 0 0 10px;
        @include breakpoint($small-down) {
          padding: 0 0 0 10px;
        }
      }
      .pr-ask-question-subtext {
        display: none;
      }
      .pr-snippet-qa-metrics {
        display: inline-block;
      }
      .pr-ask-question-link {
        color: $color-black;
        font-family: $body-font;
        font-size: 14px;
        letter-spacing: normal;
        display: inline-block;
        margin-left: 7px;
      }
    }
  }
}

#pr-questiondisplay {
  .pr-qa-display {
    background-color: $color-gray-background;
    .spp-product-layout__content-item & {
      background-color: $color-white;
    }
    .pr-qa-display-headline {
      border-bottom: 1px solid $color-black;
      h1 {
        display: none;
      }
    }
    .pr-qa-display-search {
      h2,
      a {
        color: $color-black;
        font-family: $body-font;
      }
      label {
        font-family: $body-font-bold;
        font-size: 18px;
        color: $color-black;
      }
      .pr-qa-display-searchbar {
        input:focus,
        .pr-qa-search-btn:focus {
          outline: 0;
          box-shadow: none;
        }
        input {
          border-radius: 0;
          padding-left: 10px;
        }
        input,
        .pr-qa-search-btn {
          height: 32px !important;
          border: 1px solid $color-black;
          font-family: $body-font;
        }
        .pr-qa-search-btn {
          background-color: $color-black;
        }
      }
    }
    .pr-qa-display-sort {
      .pr-qa-sort-group {
        @include breakpoint(($small-down) (orientation portrait)) {
          width: 100%;
        }
      }
      .pr-qa-sort-headline {
        font-size: 18px;
        color: $color-black;
        font-family: $body-font-bold;
      }
      label,
      select {
        font-size: 14px;
        color: $color-black;
        font-family: $body-font;
      }
      .pr-qa-sort {
        -webkit-appearance: menulist;
        background: none;
        background-color: $color-white;
        @include breakpoint(($small-down) (orientation portrait)) {
          width: 100%;
        }
      }
    }
    .pr-qa-sort-group:after {
      display: none;
    }
    .pr-qa-display-thankyou-headline {
      font-size: 18px;
      color: $color-black;
      font-family: $body-font;
    }
    .pr-qa-display-ask-question {
      .pr-question-form {
        fieldset {
          &:first-child {
            position: relative;
            right: 50%;
            @include breakpoint($small-down) {
              right: 0%;
            }
          }
        }
      }
      .pr-qa-display-btn,
      .pr-btn-ask-question {
        @include pr-button-display();
        background-color: transparent;
        .pr-qa-display-text {
          color: $color-pink;
        }
      }
      .pr-qa-display-btn:hover {
        color: $color-black;
        border-bottom: 2px solid $color-black;
        background-color: transparent;
      }
    }
    .pr-cross-icon {
      display: none;
    }
    .pr-btn-show-more {
      background: transparent;
      .pr-caret-icon__line {
        stroke: $color-black;
      }
    }
  }
  .p-w-r {
    .pr-qa-display-question {
      .pr-qa-display-questionlist {
        border-bottom: 1px solid $color-black;
        margin-bottom: 20px;
        .pr-qa-display-item {
          .pr-qa-display-item-text {
            font-size: 18px;
            color: $color-black;
            font-family: $body-font-bold;
          }
        }
        .pr-qa-display-answer-icon {
          display: none;
        }
        .pr-qa-display-item-details {
          .pr-subscript,
          time {
            display: block;
            font-size: 14px;
            color: $color-gray-dark;
            font-family: $body-font;
          }
          time {
            margin-left: 0;
          }
        }
        .pr-btn-answer {
          .pr-qa-display-text {
            @include pr-button-display();
          }
          .pr-qa-display-text:hover {
            color: $color-black;
            border-bottom: 2px solid $color-black;
          }
        }
        .pr-helpful-voting {
          .pr-helpful {
            &-btn {
              border: none;
              padding: 0 10px 0px 0px;
              margin-left: -5px;
              &.pr-helpful-yes {
                border-right: 1px solid $color-black;
              }
              &.pr-helpful-no {
                margin: 0 0px 0px 10px;
              }
              .pr-thumbs-icon {
                .pr-thumbs-fill,
                .pr-thumbs-cuff-fill {
                  fill: $color-black;
                }
              }
            }
            &-active {
              background: transparent;
              .pr-thumbs-icon {
                .pr-thumbs-fill,
                .pr-thumbs-cuff-fill {
                  fill: $color-black;
                }
              }
            }
          }
        }
        .pr-qa-display-answer-link-container {
          margin-left: 0;
        }
        .pr-qa-display-answer-details {
          border-bottom: 1px solid $color-white;
          padding-bottom: 10px;
        }
      }
      .pr-qa-display-item-icon {
        background-color: $color-black;
        top: -2px;
      }
    }
    .pr-qa-display-search {
      padding: 20px 0 20px 0px;
      background-color: transparent;
      form {
        .pr-qa-display-searchbar {
          input:focus + button.pr-qa-search-empty {
            svg {
              path {
                fill: $color-white;
              }
            }
          }
        }
      }
    }
    .pr-qa-display {
      &.pr-qa-display-desktop {
        padding-right: 0;
        .pr-qa-display-search {
          label {
            width: 335px !important;
          }
        }
        .pr-qa-display-item-details {
          left: -33%;
        }
        .pr-qa-display-headline {
          margin: 20px 0 20px 0;
        }
      }
    }
  }
  .add-your-answer-link--hide {
    .pr-qa-display-answer-link-container {
      display: none;
    }
  }
}
/* SPP Power reviews tabs section */
.responsive-tabs--powerreviews {
  text-align: center;
  max-width: 1024px;
  margin: 0 auto !important;
  .resp-tabs-container {
    background-color: $color-gray-background;
    padding: 20px 20px;
    .spp-product-layout__content-item & {
      background-color: $color-white;
    }
    @include breakpoint($small-down) {
      padding: 0;
    }
    .resp-accordion {
      @include breakpoint($small-down) {
        background-color: $color-gray-background;
        padding: 10px 20px 10px 20px;
        font-size: 15px;
        .spp-product-layout__content-item & {
          background-color: $color-white;
        }
      }
    }
  }
  .resp-tabs-list {
    display: inline-flex;
    &.clearfix {
      @include breakpoint($small-down) {
        display: none;
      }
    }
  }
  .resp-tab-item {
    margin-bottom: 0px;
    background: $color-white;
    &.resp-tab-active {
      background: $color-gray-background;
      .spp-product-layout__content-item & {
        background: $color-white;
      }
    }
  }
  .p-w-r {
    .pr-media-modal {
      .button__close {
        top: 56px;
        #{$rdirection}: 0;
        outline: none;
        &,
        &:hover,
        &:focus {
          .pr-cross-icon {
            width: 40px;
            outline: none;
            &__circle {
              stroke: none;
            }
            &__line {
              stroke: $color-black;
            }
          }
        }
      }
      .pr-media-carousel {
        .button__next,
        .button__prev {
          background: transparent;
          line-height: 4;
          .pr-caret-icon {
            width: 25px;
            &,
            &:hover {
              background: transparent;
            }
            &:focus {
              outline: none;
            }
            &__circle {
              stroke: none;
            }
          }
          &,
          &:hover {
            .pr-caret-icon__line {
              stroke: $color-black;
            }
          }
          &:focus {
            outline: none;
          }
          button {
            &:focus,
            &:hover {
              outline: none;
              .pr-caret-icon {
                background: transparent;
                &__line {
                  stroke: $color-black;
                }
              }
            }
          }
        }
      }
    }
  }
}
/* quick shop container */

.quickshop__container {
  .product__details {
    .product__rating {
      .t-desktop {
        display: none;
      }
    }
  }
}

.spp-mobile-page {
  .rating_fav {
    .product__rating {
      @include breakpoint($small-down) {
        width: auto;
      }
    }
  }
}

#pr-write .pr-email_collection-form-group {
  // Hide email field from write a review.
  /* stylelint-disable-next-line declaration-no-important */
  display: none !important;
}
