// may be used in the future

// .review-panel {
//   .promotions-container,
//   .checkbox {
//     @include hidden;
//   }
//   .submit { display: none; }
//   &.active {
//     .submit {
//       display: block;
//       position: fixed;
//       z-index: 999;
//       bottom: 0;
//       left: 0;
//       width: 100%;
//       background: #d0d0d0;
//       height: 80px;
//       border-top: 1px solid $color-black;
//       border-bottom: 1px solid $color-black;
//       input[type="submit"].btn {
//         display: block;
//         margin: 25px auto 0 auto;
//         @include breakpoint($small-down) {
//           width: auto;
//         }
//       }
//     }
//   }
// }

// 'the jessbar'
// mobile only
@include breakpoint($small-down) {
  .checkout.onepage {
    position: static;
    z-index: auto;
    margin-bottom: 0;
  }
  .review-panel {
    &.active {
      .review-panel__title {
        display: none;
      }
    }
    .promotions-container,
    .checkbox {
      @include hidden;
    }
    .submit {
      display: none;
    }
    &.active {
      .submit {
        display: block;
        position: fixed;
        z-index: 999;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #d0d0d0;
        height: 80px;
        border-top: 1px solid $color-black;
        border-bottom: 1px solid $color-black;
        input[type='submit'].btn {
          display: block;
          margin: 25px auto 0 auto;
          width: auto;
        }
      }
    }
  }
}
