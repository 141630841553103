//Site Map SCSS Starts
body.sitemap {
  .sitemap_main {
    @include breakpoint($small-down) {
      margin: 0 20px;
    }
    clear: both;
    padding: 0px;
    margin: 0px;
    font-weight: normal;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    letter-spacing: 1px;
    @include breakpoint($large-up) {
      width: 960px;
      margin: 0 auto;
    }
    a {
      text-decoration: none;
      color: #000;
      font-size: 16px;
    }
    h4 {
      font-family: $brandon-text-bold;
      padding-bottom: 10px;
    }
    a:hover {
      color: #ff4661;
    }
    li {
      margin: 0 0 13px;
      list-style: none;
    }
    ul {
      padding: 0px;
    }
    .section-title {
      margin: 0;
      font-family: $brandon-text-bold;
      font-size: 1em;
      letter-spacing: 3px;
      text-transform: uppercase;
      padding: 10px 0 10px 0;
    }
    .sitemap_container {
      @include breakpoint($landscape-up) {
        width: 30%;
        float: left;
        display: inline-block;
      }
      width: 100%;
      margin: 1% 0 1% 1.6%;
    }
    .sitemap_container a:focus {
      color: #ff4661;
      outline: 3px solid rgba(131, 192, 253, 0.5);
    }
    .siteMap_header_title {
      font-family: $brandon-text-bold;
      font-size: 1.5em;
      letter-spacing: 5px;
      text-transform: uppercase;
      border-bottom: 2px solid #000000;
      margin-top: 4px;
      text-indent: 15px;
      padding-bottom: 16px;
      margin: 20px 0;
      text-align: center;
      @include breakpoint($medium-only) {
        margin: 20px 15px;
      }
      @include breakpoint($landscape-up) {
        margin: 20px 0px;
        text-align: left;
      }
    }
    .offset_height:before {
      display: block;
      content: ' ';
      height: 90px;
      margin-top: -90px;
      visibility: hidden;
    }
  }
  .site-footer {
    float: left;
    display: inline-block;
    width: 100%;
  }
}

.invisible.focusable {
  font-size: 16px;
  font-family: $brandon-text;
  text-shadow: none;
  color: $color-black;
  text-decoration: none;
  visibility: visible;
}

.customer-service-accessabilty {
  .customer-service-header {
    padding-left: 0px;
    padding-right: 0px;
    &__title {
      text-align: left;
      text-transform: capitalize;
    }
    &__title:after {
      font-size: 50%;
      vertical-align: super;
      content: 'TM';
      letter-spacing: 0px;
    }
  }
  .customer-service-section {
    margin-top: 10px;
  }
  .customer-service-section p {
    margin: 15px 0px 0px 0px;
  }
  .customer-service-section ul {
    list-style: disc;
    margin: 10px 0px 0px 17px;
  }
  @include breakpoint($small-down) {
    padding: 0 0.5em 0 0.5em;
    .customer-service-header__title {
      text-align: center;
      text-transform: capitalize;
    }
  }
}
